import Pdf from "../pdf/Osvaldo Figueroa Resume.pdf"
const Home = () => {
    
    const onResumeClick = () => {
        window.open(Pdf);
    }


    return(
        <div className="bg-gray-900 w-full h-full font-serif flex flex-col gap-2">
            <div className="sticky top-0 text-xl flex justify-end pr-12">
                <div className="flex gap-4 mt-6">

                <a href="https://github.com/figaustin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github stroke-slate-200"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>
                </a>
                <a href="https://www.linkedin.com/in/osvaldo-figueroa-84a470230/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin stroke-slate-200"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                </a>
                <a onClick={onResumeClick}>
                    <button class="bg-transparent hover:bg-slate-900 text-teal-300 font-semibold px-1.5 py-1.5 border border-teal-500 rounded hover:text-teal-200 hover:border-teal-300">
                        Resume
                    </button>
                </a>
                </div>
            </div>
            <div className="flex flex-col mt-24 w-2/3 mx-auto">
                <div className="flex flex-col items-start gap-4 mx-auto py-20 h-screen">
                    <p className="text-slate-100 text-xl font-medium">Hello! My name is</p>
                    <p className="text-7xl text-blue-200 mb-2">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 h-24 to-green-400 font-serif font-semibold inline-flex overflow-x-hidden animate-type group-hover:animate-type-reverse whitespace-nowrap text-brand-accent will-change-transform">Osvaldo Figueroa</span>
                        <span
                            className="box-border inline-block w-1 h-10 ml-2 -mb-2 bg-white md:-mb-4 md:h-20 animate-cursor will-change-transform"
                        ></span>
                    </p>
                    <p className="text-6xl text-slate-300 font-semibold">Software Engineer</p>
                    <div className="w-1/2 text-slate-300">
                    <p className="break-words text-left">I am a Full Stack Web Developer/Software Engineer. My primary coding languages are Java, JavaScript and Python! Some frameworks I have used
                        with those coding languages are Spring Boot (Java), React (JavaScript), Flask (Python). Please check out my projects down below!
                    </p>
                    
                </div>
                <div className="w-full flex justify-center mt-12 animate-bounce">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down stroke-slate-300"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                </div>
                </div>


                <div className="flex flex-col items-start gap-4 mx-auto mb-24">
                    <p className="mb-6">
                        <span className="text-5xl bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-green-400 font-serif font-semibold">Projects</span>
                    </p>
                    <div className="grid grid-cols-3 gap-6 my-2">
                        
                        <div className="max-w-sm overflow-hidden shadow-md border border-teal-700 rounded">
                            <div className="px-6 py-4 flex flex-col gap-4">
                                <img src="https://i.imgur.com/8BdNSDP.png" />
                                <p className="text-slate-300 text-2xl font-semibold">GuildRecruiter</p>
                                <p className="break-words text-center text-slate-300">A place for gaming guilds to find post their recruitment message and find the players they need!</p>
                                <p className="break-words text-center text-slate-300">Tech Used: Java, Spring Boot, MySQL</p>
                                <div className="flex justify-center gap-2 ">
                                    <a href="https://github.com/figaustin/guildrecruiter" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github stroke-slate-200"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>
                                    </a>
                                    <a href="http://54.82.132.63/" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link stroke-slate-200"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm overflow-hidden shadow-md border border-teal-700 rounded">
                            <div className="px-6 py-4 flex flex-col gap-4">
                                <img src="https://i.imgur.com/BoPWywF.png" />
                                
                                    <p className="text-slate-300 text-2xl font-semibold">Watchd</p>
                                    <p className="break-words text-center text-slate-300">An easy way to search and track the shows you are watching</p>
                                    <p className="break-words text-center text-slate-300">Tech Used: JavaScript, React, MongoDB</p>
                                
                                <div className="flex gap-2 mx-auto">
                                    <a href="https://github.com/figaustin/watchd" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github stroke-slate-200"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>
                                    </a>
                                    <a href="http://54.82.132.63/" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link stroke-slate-200"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm overflow-hidden shadow-md border border-teal-700 rounded">
                            <div className="px-6 py-4 flex flex-col gap-4">
                                <img src="https://i.imgur.com/upJqrGZ.png"/>
                                <div>
                                    <p className="text-slate-300 text-2xl font-semibold">MilkSplash</p>
                                    <p className="break-words text-center text-slate-300">100+ downloads</p>
                                    <p className="break-words text-center text-slate-300">Minecraft/Spigot server plugin that adds a new brewable milk splash potion that cleanses players potion effects</p>
                                    <p className="break-words text-center text-slate-300">Tech Used: Java, Spigot/Paper API</p>
                                </div>
                            
                                <div className="flex justify-center gap-2 bottom-0">
                                    <a href="https://github.com/figaustin/milksplash" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github stroke-slate-200"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>
                                    </a>
                                    <a href="https://www.spigotmc.org/resources/milksplash.106326/" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link stroke-slate-200"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-sm overflow-hidden shadow-md border border-teal-700 rounded col-start-2">
                            <div className="px-6 py-4 flex flex-col gap-4">
                                <img src="https://i.imgur.com/3oDIUpf.png"/>
                                <div>
                                    <p className="text-slate-300 text-2xl font-semibold">MoodJams</p>
                                    <p className="break-words text-center text-slate-300">A unique playlist generator based on user's inputted "mood"</p>
                                    <p className="break-words text-center text-slate-300">Tech Used: Python, Flask, Spotify API</p>
                                </div>
                            
                                <div className="flex justify-center gap-2 bottom-0">
                                    <a href="https://github.com/figaustin/moodjams" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github stroke-slate-200"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>
                                    </a>
                                    <a href="#" className="hover:-translate-y-1 transition ease-in-out delay-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link stroke-slate-200"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="flex flex-col justify-start gap-4">
                    <p className="mb-6 text-left">
                        <span className="text-5xl bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-green-400 font-serif font-semibold">Experience</span>
                    </p>
                    <div className="border border-teal-500 py-4 flex flex-col gap-6">
                    <div className="w-1/2 mx-auto flex flex-col gap-4 justify-center items-center">
                        <p className="text-4xl text-slate-300 font-semibold text-left"> Student @ Coding Dojo</p>
                        <ul className="list-disc text-slate-300 w-4/5  flex flex-col gap-4">
                            <li className="break-words text-center">
                                Learned multiple languages and frameworks, how OOP works, and how to be a Full Stack Web Developer
                            </li>
                            <li>
                                Used the languages I learned to create multiple full CRUD web-apps
                            </li>
                        </ul>
                    </div>

                    <div className="w-1/2 mx-auto flex flex-col gap-4  items-center">
                        <p className="text-4xl text-slate-300 font-semibold"> Freelancer</p>
                        <ul className="list-disc text-slate-300 w-4/5  flex flex-col gap-4">
                            <li className="break-words ">
                                Using my knowledge from Coding Dojo and past experience to create web-apps to further expand my knowledge
                            </li>
                            <li>
                                Creating free open source Minecraft/Spigot server plugins/mods for the community to use
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>

                <div className="w-1/2 mx-auto text-center text-slate-300 text-sm pt-24 pb-4">
                        <p>Website portfolio created by Osvaldo Figueroa</p>
                        <p>Please contact me through LinkedIn or Email: figaustin@yahoo.com</p>
                </div>
            </div>
        </div>
    )
}

export default Home;